import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import moment from "moment";
import {
  Box,
  Container,
  Grid,
  styled,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import { getFileFromUrl } from "../../Utils";
import { ErrorMessage } from "../../Components/Modals";
import { CustomLoader } from "../../Components/Common";

const ClientDetailsWrapper = styled(Container)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
  `
);

// New box for content section with background color and padding
const ContentBox = styled(Box)(
  () => `
  background-color: #F8FBFF; /* Blue background */
  padding: 30px; /* Padding around the content */
  margin: 20px; /* Spacing from all sides */
  `
);

const DigitalSignature = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isLinkExpired, setIsLinkExpired] = useState(""); // State to track if the link is expired
  const signatureCanvasRef = useRef();
  const [imageURL, setImageURL] = React.useState(null);
  const [signImageFile, setSignImageFile] = useState(null);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format("MMM D, YYYY") : "";
    return utc;
  };

  const fetchUserDetails = async () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);

    const accessKey = params.get("accessKey");
    const secretKey = params.get("secretKey");
    const ivPartitionKey = params.get("ivPartitionKey");
    const ivSortKey = params.get("ivSortKey");
    const link = window.location.href;

    const payload = {
      accessKey,
      secretKey,
      ivPartitionKey,
      ivSortKey,
      link: link,
    };

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL3}client/verifySignatureStatus`,
        payload
      );
      if (response?.data?.message) {
        if (
          response?.data?.message ===
            "The link has expired. Please request a new email." ||
          response?.data?.message ===
            "The link has expired, and the signature already exists."
        ) {
          setLoading(false);
          setIsLinkExpired(response?.data?.message);
        } else {
          setLoading(false);
          setUserDetails(response?.data?.clientData?.[0]);
        }
      } else {
        setLoading(false);
        setIsLinkExpired("");
      }
    } catch (error) {
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error.message || "Something went wrong, Try again!");
    }
  };

  /* Function to clear the signature */
  const handleSignature = () => {
    if (imageURL) setImageURL(null);
    if (signatureCanvasRef.current) signatureCanvasRef.current.clear();
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const saveSignature = async () => {
    if (signatureCanvasRef.current && !signatureCanvasRef.current.isEmpty()) {
      const signatureImage = signatureCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setImageURL(signatureImage);

      // Create the file from URL here and return the file directly
      const file = await getFileFromUrl(signatureImage);
      setSignImageFile(file); // Save the file to state (still optional)
      return file; // Return the file directly to be used in handleSubmit
    }
    return false;
  };

  const handleSubmit = async () => {
    const params = new URLSearchParams(window.location.search);
    const accessKey = params.get("accessKey");
    const secretKey = params.get("secretKey");
    const ivPartitionKey = params.get("ivPartitionKey");
    const ivSortKey = params.get("ivSortKey");
    const link = window.location.href;

    let file = signImageFile;

    if (!file) {
      file = await saveSignature();
    }

    const formData = new FormData();
    formData.append("accessKey", accessKey);
    formData.append("secretKey", secretKey);
    formData.append("ivPartitionKey", ivPartitionKey);
    formData.append("ivSortKey", ivSortKey);
    formData.append("link", link);

    if (file) {
      formData.append("profileImage", file);
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL3}client/updateSignature`,
        formData
      );
      if (response?.data?.statusCode === 200) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response?.data?.message); // Show success message
      }
    } catch (error) {
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error.message || "Something went wrong, Try again!");
    }
  };

  return (
    <ClientDetailsWrapper maxWidth="100%" className="location-details-wrapper">
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {/* Display success message text conditionally */}
      {showSuccessMessage ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="90vh"
          overflow="hidden" // Prevent scrollbars
        >
          <Typography
            className="header-blue-20"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#003087", // Blue color for header
              letterSpacing: "0.5px",
              padding: "20px",
            }}
          >
            {successMessage}
          </Typography>
        </Box>
      ) : (
        <>
          {loading ? (
            <CustomLoader IsLoading={loading} />
          ) : isLinkExpired ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="90vh"
              overflow="hidden" // Prevent scrollbars
            >
              <Typography
                className="header-blue-20"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#003087", // Blue color for header
                  letterSpacing: "0.5px",
                  padding: "20px",
                }}
              >
                {isLinkExpired}
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    className="header-blue-40"
                    sx={{ textAlign: "center" }}
                  >
                    Zoom Room - Liability Waiver
                  </Typography>
                </Grid>
              </Grid>
              <ContentBox className="location-details-container">
                <Grid
                  container
                  spacing={2}
                  mt={3}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-label">NAME</Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-value">
                      {userDetails?.firstName && userDetails?.lastName
                        ? `${userDetails?.firstName} ${userDetails?.lastName}`
                        : "N/A"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-label">EMAIL</Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-value">
                      {userDetails?.email || "N/A"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-label">PHONE NO.</Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-value">
                      {userDetails?.mobilePhone || "N/A"}
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  mt={3}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-label">
                      REGISTRATION DATE
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-value">
                      {dateFormate(userDetails?.createdAt) || "N/A"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-label">STORE NAME</Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className="location-details-col">
                    <Box className="location-details-value">
                      {userDetails?.locationName || "N/A"}
                    </Box>
                  </Grid>
                </Grid>

                <Grid container mt={3} className="location-details-row">
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="header-blue-24">
                      Liability Waiver
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} className="location-details-col">
                    <Box
                      className="liabilityWaiverText"
                      dangerouslySetInnerHTML={{
                        __html: userDetails?.liabilityWaiverContent || "N/A",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container mt={2} className="location-details-row">
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="header-blue-24">
                      Signature
                    </Typography>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} sm={12}>
                      {imageURL ? (
                        <img
                          src={imageURL}
                          alt="my signature"
                          className="signature-class"
                        />
                      ) : (
                        <SignaturePad
                          canvasProps={{
                            className: "signatureCanvasSignaturePage",
                            alt: "Sign Here",
                          }}
                          penColor="#003087"
                          backgroundColor="#fff"
                          ref={signatureCanvasRef}
                        />
                      )}

                      <Button
                        sx={{
                          border: "2px solid #003087 !important",
                          color: "#003087 !important",
                          display: "inline-block",
                          marginTop: "100px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                        }}
                        variant="outlined"
                        className="btn-primary"
                        onClick={handleSignature}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        mt: 4,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={handleSubmit}
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#21b6ae",
                          fontSize: "12px",
                          height: "50px",
                          width: "115px",
                          padding: "17px 20px",
                        }}
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </ContentBox>
            </>
          )}
        </>
      )}
    </ClientDetailsWrapper>
  );
};

export default DigitalSignature;
