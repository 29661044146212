import { getLocalData } from "../../Utils";
import { API_URL_4, OPEN_API_URL } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";

export const uploadEventData = async (payload) => {
    try {
        return await ApiServices.post(`${API_URL_4}uploadEventData`, payload);
    } catch (error) {
        console.error(error.message);
        return error;
    }
};

export const getEventData = async () => {
    try {
        const clientId = encodeURIComponent(getLocalData("clientId"));
        return await ApiServices.get(`${API_URL_4}getEventData?clientId=${clientId}`);
    } catch (error) {
        console.error(error.message);
        return error;
    }
};

export const getStoreDetails = async () => {
    let franchiseeId = encodeURIComponent(getLocalData("franchiseeId") || '');
    let locationId = encodeURIComponent(getLocalData("locationId") || '');

    try {
        const response = await ApiServices.get(
            `${OPEN_API_URL}locations/getLocationById?franchiseeId=${franchiseeId}&locationId=${locationId}&status=1`
        );
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};

